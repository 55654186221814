export default () => ({
  dataPoints: [],
  isLoading: false,
  isInitialized: false,
  fetchable: true,
  filters: {
    date: {
      from: new Date("2018-02-01").toISOString().substr(0, 10),
      to: new Date().toISOString().substr(0, 10)
    }
  }
});
